import React, {Component} from 'react';
import {Button, Card, CardBody, CardImg, CardText, CardTitle, Container} from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import VenicVsCode from '../../assets/images/venic-vs-code3.png';
import DownloadCard from "../../Layouts/Download/DownloadCard";

class DownloadSection extends Component {
    // constructor(props) {
    //     super(props);
    // }

    style = {
        background: '#494949'
    }

    cardStyle = {
        height: 180
    }

    render() {
        return (
            <React.Fragment>
                <section className="section  bg-dark" id="download">
                    <Container>
                        <SectionTitle
                            title1=""
                            title2="Download"
                            desc=""
                        />
                        <h4 className='text-center text-white pb-4'>Venic Installation Steps</h4>

                        <DownloadCard number={'#'}
                                      title={'Installing Prerequisites.'}
                                      subTitle={''}
                                      subTitleOne={'Python 3.8 / 3.9'}
                                      subTitleTwo={''}/>
    
                        <DownloadCard number={'1.'}
                                      title={'Install the Visual Studio Code extension from the visual studio code marketplace.'}
                                      subTitle={''}
                                      subTitleOne={''}
                                      subTitleTwo={''}/>

                        <Card className="my-2">
                            <CardImg alt="Card image cap" src={VenicVsCode} style={this.cardStyle} top width="100%"/>
                            <CardBody>
                                <CardTitle tag="h3">
                                    VENIC Visual Studio Code Extension
                                </CardTitle>
                                <CardText>
                                    Download our latest version of Visual Studio Code Extension from Visual Studio
                                    Marketplace. Click on Download Button here.
                                </CardText>
                                <CardText>
                                    <small className="text-muted">
                                        Version 0.1.4
                                    </small>
                                </CardText>

                                <Button color="warning" href={'https://marketplace.visualstudio.com/items?itemName=VENIC.venic'}>
                                    DOWNLOAD<i className="mdi mdi-download ml-2"></i>
                                </Button>
                            </CardBody>
                        </Card>

                        <DownloadCard number={'2.'}
                                      title={'Open Visual Studio Code run window.'}
                                      subTitleOne={'On Microsoft Windows and Linux based systems Ctrl + Shift + P'}
                                      subTitleTwo={'On Mac Cmd + Shift + P'}/>

                        <DownloadCard number={'3.'}
                                      title={'Type venic. It will display the supported commands.'}
                                      subTitleOne={''}
                                      subTitleTwo={''}/>

                        <DownloadCard number={'4.'}
                                      title={'Choose venic.download-and-install'}
                                      subTitleOne={''}
                                      subTitleTwo={''}/>

                        <DownloadCard number={'5.'}
                                      title={'A terminal output will be displayed with progress. Wait for it to finish.'}
                                      subTitleOne={''}
                                      subTitleTwo={''}/>

                        <DownloadCard number={'6.'}
                                      title={'Click Venic tab in the side ribbon to open Venic main GUI.'}
                                      subTitleOne={''}
                                      subTitleTwo={''}/>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default DownloadSection;
