import React from 'react';
import {Card, CardBody, CardText, CardTitle} from "reactstrap";

function DownloadCard({number, title, subTitleOne, subTitleTwo}) {
    return (
        <Card className="my-2">
            <CardBody>
                <CardTitle tag="h3">{number} {title}</CardTitle>
                <CardText>{subTitleOne}</CardText>
                <CardText>{subTitleTwo}</CardText>
            </CardBody>
        </Card>
    );
}

export default DownloadCard;
